import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import kakegawa_jpg from '../img/ismfc-blog_kakegawa.png';
import miura_jpg from '../img/ismfc-blog_miura.jpg';
import matsuda_jpg from '../img/ismfc-blog_matsuda.png';
import tamaki_jpg from '../img/ismfc-blog_tamaki.png';
import nagaishi_jpg from '../img/ismfc-blog_nagaishi.jpg';
import hirata_jpg from '../img/ismfc-blog_hirata.jpg';
import ishii_jpg from '../img/ismfc-blog_ishii.png';

const m = {
  'r-kakegawa': kakegawa_jpg,
  'k-miura': miura_jpg,
  's-matsuda': matsuda_jpg,
  'y-tamaki': tamaki_jpg,
  'y-hirata': hirata_jpg,
  't-nagaishi': nagaishi_jpg,
  'r-ishii': ishii_jpg,
}

const BlogBanner = ({talent}) => <div css={{
  backgroundImage: `url(${m[talent.id]})`,
  backgroundSize: '100% 100%',
  paddingTop: `${441/1341*100}%`
}}></div>;

export default BlogBanner;