import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import styled from '@emotion/styled';
import {Link} from 'react-router-dom';
import Button, { ImageButton, OutlinePillNavButton, FollowButton } from '../ui/button';
import theme from '../ui/theme';
import ArticleTile from '../ui/article-tile';
import StaffArticleTile from '../ui/staff-article-tile';
import AspectRatio from '../ui/aspect-ratio';
import btn_sinki_png from '../img/btn_sinki.png';
import btn_mypage_png from '../img/btn_mypage.png';
import btn_tokutenannai_png from '../img/btn_tokutenannai.png';
import makeAPI from '../api';
import App from '../app';
import Container from '../ui/container';
import {OutlineButton} from '../ui/button';
import Carousel, {CarouselController} from '../ui/carousel';
import SectionLabel from '../ui/section-label';
import ActivityTile from '../ui/activity-tile';
import talentPosterImage from '../ui/talent-poster-image';
import top_jpg from '../img/ismfc-top.png';
import top_2024_jpg from '../img/ismfc-top2024.jpg';
import top_ttl_activities_png from '../img/top_ttl_activities.png';
import top_ttl_artist_png from '../img/top_ttl_artist.png';
import top_ttl_news_png from '../img/top_ttl_news.png';
import moment from 'moment';

export default () => {
  const app = React.useContext(App);

  const [staffNews, setStaffNews] = React.useState([]);
  const [talentNews, setTalentNews] = React.useState([]);
  const [activities, setActivities] = React.useState([]);
  const [talents, setTalents] = React.useState([]);
  
  React.useEffect(() => {

    const api = makeAPI();

    api('/staffs/news')
      .then(res => res.data.news)
      .then(news => setStaffNews(news));
    
    api('/talents/news')
      .then(res => res.data.news)
      .then(news => setTalentNews(news));

    api('/talents')
      .then(res => res.data.talents)
      .then(talents => setTalents(talents))

    api('/activities')
      .then(res => res.data.activities)
      .then(activities => setActivities(activities));    
  }, []);

  return <Home app={app} staffNews={staffNews} talentNews={talentNews} activities={activities} talents={talents}/>;
};

export const Home = ({app, staffNews, talentNews, activities=[], talents}) => {
  const sw = moment().isAfter('2024-01-01 00:00:00', 'second')
  const hero = <div css={css`
    background-image: url(${top_jpg});
    background-size: 100% 100%;
    padding-top: 100%;
  `}/>

  return (
    <div>
      {hero}
      {!app.signed ? (
        <>
          <div css={{backgroundColor: '#E8ECEF', padding: '50px 20px'}}>
            <div>
              <ImageButton src={btn_sinki_png} to="/signup" aspectRatio={160/670}/>
            </div>
            <div css={{
              marginTop: 10,
              textAlign: 'center',
              color: theme.colors.accent,
              textDecoration: 'underline'
            }}>
              <Link to="/login">ご登録済みの方はこちらからログイン</Link>
            </div>
          </div>
          <Container css={{margin: '25px 25px 0'}}>
            <ImageButton src={btn_tokutenannai_png} to="/guide" aspectRatio={100/672}/>
          </Container>
        </>
      ) : <Container css={{marginTop: 30}}>
        <ImageButton src={btn_mypage_png} to="/mypage" aspectRatio={160/670}/>
      </Container>}
      <Section label="事務局からのお知らせ">
        <ul>
          {staffNews.slice(0, 5).map(n => <StaffArticleTile key={n.id} article={n}/>)}
        </ul>
        <div css={{display: "flex", justifyContent: 'flex-end', marginTop: 20}}>
          <OutlinePillNavButton to="/staffs/news/" color={theme.colors.accent} children="事務局からのお知らせ一覧"/>
        </div>
      </Section>
      <Section decorated image={top_ttl_activities_png}>
        {activities.slice(0, 5).map(a => a.talent && <ActivityTile link={app.signed} key={a.id} activity={a}/>)}
        <div css={{display: "flex", justifyContent: 'flex-end', marginTop: 20}}>
          <OutlinePillNavButton to="/activities/" color={theme.colors.accent} children="Activities一覧"/>
        </div>
      </Section>
      <Section decorated image={top_ttl_artist_png} css={{position: 'relative'}}>
        <TalentCarousel talents={talents} signed={app.signed}/>
      </Section>
      <Section image={top_ttl_news_png} decorated>
        <ul>
          {talentNews.slice(0, 5).map(n => <ArticleTile key={n.id} article={n}/>)}
        </ul>
        <div css={{display: "flex", justifyContent: 'flex-end', marginTop: 20}}>
          <OutlinePillNavButton color={theme.colors.accent} to="/talents/news/" children="ニュース一覧"/>
        </div>
      </Section>
      {!app.signed && (
        <Container>
          <ImageButton src={btn_sinki_png} to="/signup" aspectRatio={160/670}/>
        </Container>
      )}
    </div>
  );
}

const TalentCarousel = ({talents, signed}) => {
  const $controller = React.useRef(new CarouselController(0.0, page => setPage(page)));
  const [page, setPage] = React.useState(0.0);
  let now = Math.round(page);
  let max = talents.length - 1;

  return (
    <AspectRatio>
      <div>
        <Carousel controller={$controller.current}>
          {talents.map(t =>
            <Slide key={t.id} src={talentPosterImage(t.id)} to={`/artists/${t.id}`} artist={t} signed={signed}/>
          )}
        </Carousel>
        <svg width="15" height="20" onClick={() => $controller.current.moveTo(now == 0 ? max : now - 1)} css={{left: 15, top: 85, bottom: -85, margin: 'auto', position: 'absolute'}}>
          <polygon points="0 10 15 0 15 20" fill="#CCC"/>
        </svg>
        <svg width="15" height="20" onClick={() => $controller.current.moveTo(now == max ? 0 : now + 1)} css={{right: 15, top: 85, bottom: -85, margin: 'auto', position: 'absolute' }}>
          <polygon points="15 10 0 0 0 20" fill="#CCC"/>
        </svg>
      </div>
    </AspectRatio>
  );
};1

const Slide = ({src, to, artist, signed}) => <span to={to} css={css`
  display: block;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-image: url(${src});
  position: relative;
`}>
  <div css={css`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 3%;

  & > div {
    margin: 0 0 10px;
  }
`}>
    <OutlinePillNavButton color={theme.colors.accent} to={to} children="ARTIST トップ"/>
  </div>
</span>;

const Arrow = styled.span`
  position: absolute;
  display: inline-block;
  padding: 0 0 0 16px;
  color: #000;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
  width: 20px;
  height: 40px;
  top: 70%;
  overflow: hidden;

  &:before,
  &:after{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
  }
`;
const Next = styled(Arrow)`
  &:before {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border: 20px solid transparent;
    border-left: 20px solid #cccccc;
  }
  right: 0;
  cursor: pointer;
`;
const Prev = styled(Next)`
  transform: rotate(180deg);
  left: 0;
  right: auto;
`;

const Section = ({label, image, decorated, children}) => (
  <section css={{padding: '40px 0'}}>
    <Container>
      <SectionLabel css={{padding: '0 20px'}} label={label} image={image} decorated={decorated}/>
      {children}
    </Container>
  </section>
);
