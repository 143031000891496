import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import image from './talent-avatar-image';

export default ({talent, radius=25}) => <span css={css`
  display: inline-block;
  background-image: url(${image(talent.id)});
  background-size: 100% 100%;
  // border-radius: 50%;
  width: ${radius*2.4}px;
  height: ${radius*2.4}px;
  // box-shadow: 0 4px 4px 1px #DDD;
  transform: translateY(${radius*0.4 / 2}px);
`}/>;